import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import PageHeader from '../../components/Layout/PageHeader'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import {Inner} from '../../components/styles'
import QuoteIcon from '../../images/icons/icon-quote.svg'

const PageWrapper = styled.div`
  .no-margin-bottom {
    margin-bottom: 0 !important;
  }
  .bg-grey4 {
    background-color: #f8f8f8;
  }
  .wp-block-columns.content-align-center {
    align-items: center;
  }
  @media (min-width: 768px) {
    .wp-block-columns.has-2-columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  .wp-block-columns {
    margin: 40px 0;
  }
  .wp-block-column {
    padding: 40px;
  }
  .wp-block-columns.no-padding {
    grid-gap: 40px;
  }
  .wp-block-columns.no-padding .wp-block-column {
    padding: 0;
  }
  .wp-block-image.full-width {
    margin-left: -40px;
    margin-right: -40px;
  }
  @media (min-width: 1240px) {
    .wp-block-image.full-width {
      margin-left: calc(-100vw / 2 + 1200px / 2);
      margin-right: calc(-100vw / 2 + 1200px / 2);
    }
  }
  .figure {
    margin: 0;
  }
  .wp-block-image img {
    width: 100%;
  }
  .wp-block-image.no-margin {
    margin: 0;
    padding: 0;
    line-height: 0;
  }
  .wp-block-image {
    margin: 40px 0;
  }
  .wp-block-image.no-margin {
    margin: -40px;
  }
  blockquote {
    margin: 0;
  }
  .wp-block-quote {
    padding: 40px;
  }
  .wp-block-quote p {
    font-size: 23px;
    line-height: 30px;
    font-weight: 400;
  }
  .wp-block-quote p,
  blockquote cite {
    max-width: 1240px;
    margin: 0 auto;
    padding: 20px 20px 20px 100px;
    text-align: right;
    display: block;
    font-style: normal;
  }
  blockquote cite {
    opacity: 0.7;
  }
  .wp-block-quote.bg-grey1 p,
  .wp-block-quote.bg-grey2 p {
    color: #fff;
    position: relative;
  }
  .wp-block-quote p:before {
    content: url(${props => props.QuoteIcon});
    position: absolute;
    top: 10px;
    left: 0;
  }
  .wp-block-quote.full-width {
    margin-left: -20px;
    margin-right: -20px;
  }
  @media (min-width: 1240px) {
    .wp-block-quote.full-width {
      margin-left: calc(-100vw / 2 + 1200px / 2);
      margin-right: calc(-100vw / 2 + 1200px / 2);
    }
  }
`

const OurStoryPage = ({location}) => {
  const data = useStaticQuery(graphql`
    query GET_ABOUT_OUR_STORY_PAGE_DATA {
      wp: wordpressPage(wordpress_id: {eq: 14}) {
        title
        content
      }
      seo: wpgraphql {
        pageBy(pageId: 14) {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage
            twitterTitle
            twitterDescription
            twitterImage
          }
        }
      }
    }
  `)
  return (
    <Layout location={location} flush>
      <SEO location={location} seo={data.seo.pageBy.seo} title="Our Story" />
      <PageHeader location={location}>{data.wp.title}</PageHeader>
      <Inner>
        <PageWrapper
          QuoteIcon={QuoteIcon}
          className="content"
          dangerouslySetInnerHTML={{__html: data.wp.content}}
        />
      </Inner>
    </Layout>
  )
}

export default OurStoryPage
